body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.mantine-Select-dropdown {
  z-index: 9999; /* Ensure it's on top of other elements */
}


::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  border-radius: 18px;
  border: 1px solid #0b3d6a;
  background: white;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #0b3d6a;
}

.MuiTableRow-root:hover {
  background-color: #f5f5f5 !important; 
  transition: background-color 0.3s ease;
}

