/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import './mixins.scss';


:root {
    --primary-color: #0b3d6a;
}

* {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    background-color: #F9F9F9;
}

html,
body {
    height: 100%;
}

a {
    text-decoration: none;
}

.deshboard-header {
    background-color: #ffffff;
    padding: 10px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 16px rgba(153, 153, 153, 0.08);
    position: relative;
    z-index: 1;

    .logo {
        max-width: 135px;
        display: inline-block;
        vertical-align: middle;

        img {
            max-width: 100%;
        }
    }

    .welcome-name {
        display: inline-block;
        vertical-align: middle;
        padding-left: 80px;

        h5 {
            margin: 0;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
        }

        .date {
            span {
                color: #646464;
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
}

.user {
    .user-dropdown {
        .name {
            background: #B9BEE9;
            width: 40px;
            height: 40px;
            display: grid;
            place-content: center;
            font-weight: 400;
            font-size: 24px;
            color: #0b3d6a;
            border-radius: 50%;
            text-align: center;
            line-height: 40px;
            
        }

        a.box {
            padding: 4px 16px;
            display: inline-block;

            &.active {
                background: #EFEFEF;
                border-radius: 8px;
            }

            &:after {
                content: "";
                display: inline-block;
                position: relative;
                background: url(../images/down-arrow.svg) no-repeat;
                width: 100%;
                height: 20px;
                vertical-align: sub;
                background-position: center;
                margin: auto;
                background-size: 12px auto;
            }
        }

        .dropdown {
            background: #FFFFFF;
            box-shadow: 0px 4px 24px rgba(153, 153, 153, 0.16);
            border-radius: 6px;
            max-width: 155px;
            position: absolute;
            right: 20px;
            opacity: 0;
            visibility: visible;
            transition: all ease-in-out 0.5s;
            margin-top: 10px;
            display: none;

            &.show {
                opacity: 1;
                visibility: visible;
                display: block;
            }

            &:before {
                content: "";
                display: inline-block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 10px 15px 10px;
                border-color: transparent transparent #ffffff transparent;
                position: absolute;
                top: -10px;
                right: 40px;
            }

            .close {
                padding: 8px 16px;
                display: inline-block;
                font-size: 16px;
                line-height: 100%;
                width: 100%;
                color: #0D0D0D;
                background: #FFFFFF;
                box-shadow: 0px 8px 24px rgba(153, 153, 153, 0.1);
                border-radius: 6px;
                position: relative;

                &::after {
                    content: "";
                    background: url(../images/clear.svg) no-repeat;
                    width: 21px;
                    height: 21px;
                    display: inline-block;
                    vertical-align: middle;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            ul {
                list-style: none;
                padding: 10px 0;
                margin: 0;
                position: relative;

                li {
                    display: inline-block;
                    width: 100%;

                    a {
                        display: flex;
                        width: 100%;
                        color: #333333;
                        padding: 5px 15px;
                        align-items: center;
                        margin-bottom: 5px;

                        img {
                            width: 20px;
                            margin-right: 10px;
                        }

                        &:hover {
                            background-color: #f1f1f1;
                        }
                    }
                }
            }
        }
    }
}

.filter-icon {
    position: absolute;
}

.main-content {
    display: flex;
    flex-wrap: wrap;
    
}

.mb-24 {
    margin-bottom: 24px;
}

.react-datepicker__close-icon::after {
    background-color: #868688 !important;
}

.sidebar-menu {
    .back-menu {
        margin-top:20px;
        margin-bottom: 20px;

        a {
            // background: #F2F2F2;
            // border-bottom: 2px solid #838181;
            // border-radius: 6px;
            padding: 6px;
            display: flex;
            justify-content: end;
            line-height: 0;
        }
    }
}


.sidebar-menu {
    width:300px;
    height: max-content;
    overflow-y: auto;
    padding: 0 10px;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    padding-top: 96px;
    height: 100vh;
    transition: all ease-in-out 0.5s;

    @at-root .menu-icon#{&} {
        width: 105px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .menu {
        width: 100%;
  
        ul {
            list-style: none;
            padding: 0;

            li {
                display: inline-block;
                margin-bottom: 20px;
                width: 100%;

                a {
                    border-radius: 6px;
                    padding: 6px 16px;
                    font-weight: 400;
                    font-size: 16px;
                    color: #333333;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    transition: all ease-in-out 0.5s;
                    overflow: hidden;

                    span {
                        margin-left: 5px;
                        white-space: nowrap;

                        @at-root .menu-icon#{&} {
                            display: none;
                        }
                    }
                }

                &.active,
                &:hover {
                    a {
                        color: #ffffff;
                        background: var(--primary-color);
                        box-shadow: 0px 2px 4px rgba(153, 153, 153, 0.08);

                        img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
                        }
                    }
                }
            }
        }
    }
}

.content-body {
    height: 100%;
    margin-top: 90px;
    width: calc(100% - 260px);
    margin-left: 280px;
    padding: 25px 40px;
    transition: all ease-in-out 0.5s;

    &.SchedulingDetail {
        padding-left: 0;

        .title-header {
            padding-left: 40px;
        }

        .list-box {
            background: #e5f3ff;
            padding: 20px;
        }

        ;
    }

}

.SchedulingDetail {
    .dashboard-content {
        display: flex;
    }

    .left {
        width: 280px;
    }

    .list-box {
        h3 {
            color: #333;
            font-size: 24px;
            font-weight: 600;
            text-align: center;

            +div {
                text-align: center;
                margin-top: 33px;
            }
        }

        input {
            width: 200px;
            padding: 8px 16px;
            border-radius: 6px;
            border: 0.5px solid var(--primary-color);
            background: #FFF;
            box-shadow: 0px 2px 4px 0px rgba(153, 153, 153, 0.08);
        }
    }

    .program-list {
        margin-left: 30px;
        margin-top: 16px;

        .treeview {
            .list-group {
                --bs-list-group-bg: transparent;
                --bs-list-group-border-width: 0;
                --bs-list-group-item-padding-x: 0;
            }

            ul {
                padding-left: 0;
            }
        }
    }

    .program-header {
        color: #333;
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
        position: relative;
        padding-left: 18px;

        &::before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: var(--primary-color);
            left: 0;
            top: 3px;
        }
    }
}

.sidebar-menu.menu-icon+.content-body {
    width: calc(100% - 105px);
    margin-left: 105px;
}

.breadcrumbs {
    width: 100%;

    ul {
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;

        li {
            color: #999999;
            color: #9B9B9B;
            font-weight: 500;
            cursor: pointer;

            a {
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: 0em;
                text-align: left;

                transition: all ease-in-out 0.3s;

                &:hover {
                    color: #999999;
                }
            }

            &.active {
                color: #000000;
                font-size: 20px;
                color: #333333;
            }

            &::after {
                content: "";
                display: inline-block;
                background: url(../images/arrow_right.svg) no-repeat;
                width: 14px;
                height: 14px;
                vertical-align: middle;
                margin: 0 5px;
            }

            &:last-child::after {
                display: none;
            }

            &:hover {
                color: #000000;
            }
        }
    }
}

.title-header {
    color: #333;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.dashboard-content {
    width: 100%;

    .top-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left-side {
            padding-right: 30px;

            h3 {
                margin: 0;
                color: #706f6f;
                font-weight: 500;
                font-size: 20px;
                line-height: 100%;
            }
        }

        .right-side {
            .icon-list {
                display: flex;
                flex-wrap: wrap;

                .icon {
                    background: #FFFFFF;
                    border: 0.5px solid #E6E6E6;
                    border-radius: 6px;
                    padding: 6px;
                    display: flex;
                    align-items: center;
                    margin-right: 15px;
                    height: 36px;
                }

                .view-list {
                    display: flex;

                    .icon {
                        margin: 0;

                        &.grid-icon {
                            border-right: 0;
                            border-radius: 6px 0 0 6px;
                        }

                        &.list-icon {
                            border-left: 0;
                            border-radius: 0px 6px 6px 0px;
                        }

                        &.active {
                            background: var(--primary-color);
                            border-color: var(--primary-color);

                            img {
                                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
                            }
                        }
                    }
                }
            }
        }
    }
}

.table {
    table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
            text-align: left;
            // background-color: #ffffff;
        }

        td {
            font-size: 14px;
            color: #333333;
            padding: 15px 20px;
            vertical-align: middle;
            height: 60px;
            border-bottom: 1px solid #e5f3ff;
        }

        th {
            // background: #e5f3ff;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            // color: #927F7F;
            padding: 10px 20px;
            height: 48px;
        }
    }
}

.table-pagination {
    background: #F2F2F2;
    border-style: solid;
    border-color: #E6E6E6;
    border-width: 0px 1px 1px 1px;
    padding: 10px;
    display: flex;

    .pagination-count {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .count {
            background: #F2F2F2;
            width: 120px;
            font-weight: 500;
            font-size: 14px;
            color: #333333;
        }

        .pagination-arrow {
            background: #F2F2F2;
            width: 60px;
            display: flex;
            justify-content: space-between;

            .prev {
                width: 20px;
                height: 20px;
                display: inline-block;
                text-align: center;

                &.disable {
                    opacity: 0.3;
                }
            }

            .next {
                width: 20px;
                height: 20px;
                text-align: center;
                display: inline-block;

                &.disable {
                    opacity: 0.3;
                }
            }
        }
    }
}

.btn-red {
    background: var(--primary-color);
    border-radius: 8px;
    padding: 13px 24px;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    line-height: 20px;
}



.main {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    .welcome {
        width: 100%;

        img {
            object-fit: cover;
            height: 100vh;
            width: 100%;
        }

        .welcome-content {
            position: absolute;
            bottom: 10%;
            left: 120px;
            color: #ffffff;
            max-width: 430px;

            &.forget-content {
                p {

                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 26px;


                    span {


                        font-family: Roboto;
                        font-size: 25px;
                        font-weight: 500;
                        line-height: 26px;


                    }
                }
            }

            img {
                width: auto;
                height: auto;
                margin-bottom: 10px;
            }

            h1 {
                font-family: Roboto;
                font-size: 48px;
                font-weight: 500;
                line-height: 52px;
                letter-spacing: 0px;
                text-align: left;

                // font-size: 48px;
                // line-height: 52px;
                // margin-bottom: 10px;
            }

            p {
                // font-size: 24px;
                // line-height: 34px;

                font-family: Roboto;
                font-size: 24px;
                font-weight: 400;
                line-height: 32px;
                letter-spacing: 0px;
                text-align: left;

            }
        }
    }

    .login-block {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .login-form {


            background: white;
            max-width: 535px;
            padding: 60px;
            box-shadow: 0px 8px 24px rgba(112, 144, 176, 0.15);
            border-radius: 6px;
            width: 100%;
        }
    }
}

.login-form {
    .title {
        padding-bottom: 40px;

        h2 {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: #0D0D0D;
            margin: 0 0 5px;
        }

    }

    .form-label {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #0D0D0D;
    }

    .form-group {
        margin-bottom: 20px;

        .error {
            color: #CA4F11;
            margin-top: 10px;
            display: inline-block;
            width: 100%;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0px;
            text-align: left;


        }

        &.error-msg {
            .input-group {
                border-color: #CA4F11;
                box-shadow: 0px 4px 8px rgba(202, 79, 17, 0.16);
            }
        }

        .input-group {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            border: 1px solid #CCCCCC;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 6px;
            padding: 13px 20px;

            .input-group-prepend {
                min-width: 21px;
                line-height: 0;
            }

            .form-control {
                border: none;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #999999;
            }
        }
    }

    .form-check-input:checked {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
    }

    .form-check-label {
        color: #272727;
        font-size: 14px;
        line-height: 20px;
    }

    .text-right {
        text-align: right;

        a.forgot {
            color: #CA4F11;
            display: inline-block;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .btn.btn-block {
        background: var(--primary-color);
        box-shadow: 0px 16px 32px rgba(153, 153, 153, 0.24);
        border-radius: 6px;
        color: #ffffff;
        display: block;
        width: 100%;
        margin-top: 30px;
        font-size: 16px;
        line-height: 24px;
        // padding: 13px 30px;
        font-weight: 500;
        transition: all ease-in-out 0.5s;

        &:hover {
            background-color: #0D0D0D;
            color: #ffffff;
        }
    }
}


.retun-block {
    margin-top: 40px;
    text-align: center;

    p {
        color: #333333;
        font-size: 14px;
        line-height: 20px;
    }

    a {
        color: var(--primary-color);

        &:hover {
            color: #333333;
        }
    }
}

.smalltext {
    color: grey;
    font-size: 12px;
}

/* Define a style for even rows (1st, 3rd, 5th, etc.) */
table tr:nth-child(even) {
    // background: #f2f2f2 !important;
    background: #ffffff !important;
}

/* Define a style for odd rows (2nd, 4th, 6th, etc.) */
table tr:nth-child(odd) {
    background: #ffffff !important;
}

.default-odd {
    display: inline-flex;
    padding: 14px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: #FAEAE9;
    color: var(--primary-color);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 16px */
    border: none;

    &:hover {
        color: var(--primary-color);
        // background: var(--primary-color);
        border: 1px solid var(--primary-color);
    }
}

.default-even {
    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 16px */
    display: inline-flex;
    padding: 14px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: var(--primary-color);
    border: none;

    &:hover {
        color: var(--primary-color);
        background: #FFF;
        border: 1px solid var(--primary-color);
    }
}

.grey-text16 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #999999;

}

a {
    cursor: pointer;
}



/*=================== Boostrap Overwrite ====================*/
.btn {
    font-size: 16px;
    height: 44px;
    display: flex;
    padding: 7px 20px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);

    // &:hover, &:active, &:focus, &.active, &:hover:not(.btn-check), &:first-child:active {
    //     background-color: #c32b22;
    //     border-color: #c32b22;
    // }

    &:disabled {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
    }
}

.btn-danger {
    background-color: #FAEAE9;
    border-color: #FAEAE9;
    color: var(--primary-color);

    &:hover,
    &:active,
    &:focus,
    &.active,
    &:hover:not(.btn-check),
    &:first-child:active {
        background-color: #c32b22;
        border-color: #c32b22;
        color: #ffffff;
    }

    &:disabled {
        background-color: #FAEAE9;
        border-color: #FAEAE9;
        color: var(--primary-color);
    }
}

.btn-secondary {
    background-color: transparent;
    border-color: var(--primary-color);
    color: var(--primary-color);

    &:hover,
    &:active,
    &:focus,
    &.active,
    &:hover:not(.btn-check),
    &:first-child:active {
        background-color: #c32b22;
        border-color: #c32b22;
        color: #ffffff;
    }

    &:disabled {
        background-color: transparent;
        border-color: var(--primary-color);
        color: var(--primary-color);
    }
}

/*=================== Boostrap Overwrite ====================*/


/*=================== CMS table start here ==================*/
.cms-table-list {
    width: 100%;
    border: none;
    border-collapse: collapse;

    th {
        // background: #0b3d6ae6;
        padding: 14px 15px;
        font-size: 14px;
       
        font-weight: 500;
        line-height: 20px;

        label {
            font-size: 14px;
            // color: white !important;
            font-weight: 500;
            line-height: 20px;
            padding-right: 5px;
        }

        span {
            cursor: pointer;
        }

        &:first-child {
            padding-left: 30px;
        }

        &:last-child {
            padding-right: 30px;
        }
    }

    td {
        background: #ffffff;
        padding: 18px 15px;
        font-size: 14px;
        color: #333333;
        font-weight: 400;
        line-height: 24px;
        border-bottom: 1px solid #e5f3ff;

        &:first-child {
            padding-left: 30px;
        }

        &:last-child {
            padding-right: 30px;
        }
    }

    .table-action-group {
        white-space: nowrap;
        display: flex;
        align-items: center;

        .action-label {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            margin: 0px;
            padding: 0 10px;
        }

        .table-icon {
            margin: 0 10px;
            cursor: pointer;

            &:first-child {
                margin-left: 0px;
            }

            &:last-child {
                margin-right: 0px;
            }
        }
    }

    .icon-group {
        white-space: nowrap;
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;

        &:last-child {
            padding-right: 0px;
        }

        &:first-child {
            padding-left: 0px;
        }
    }
}

/*=================== CMS Table End Here ====================*/


/*=================== Switch Check Start Here ===============*/
.switch-check {
    display: flex;
    align-items: center;
    position: relative;

    input[type=checkbox] {
        height: 0;
        width: 0;
        visibility: hidden;
        position: absolute;
    }

    label {
        cursor: pointer;
        text-indent: 50px;
        width: 42px;
        height: 24px;
        background: #FFE2E1;
        display: block;
        border-radius: 12px;
        position: relative;
        line-height: 26px;
    }

    label:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 14px;
        height: 14px;
        background: var(--primary-color);
        opacity: 0.5;
        border-radius: 90px;
        transition: 0.3s;
    }

    input:checked+label {
        background: #4FC17E;
    }

    input:checked+label:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
        background: #ffffff url(/assets/images/switch-check.svg) no-repeat;
        opacity: 1;
        width: 20px;
        height: 20px;
        top: 2px;
        background-position: center;
    }

    .label {
        color: #333333;
        font-weight: 400;
        font-size: 14px;
        margin-left: 8px;
    }
}

/*=================== Switch Check End Here =================*/



/*=================== Form Container Start Here ===============*/


.form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    h4 {
        font-weight: 700;
        font-size: 24px;
        margin: 0px;
        padding-bottom: 15px;
        width: 100%;
    }

    .form-field {
        width: 100%;
        display: flex;
        flex-direction: column;



        &>input,
        &>select,
        &>textarea,
        .react-datepicker__input-container input {
            height: 48px;
            width: 100%;
            @include border-radius(6px);
            border: 1px solid #E6E6E6;
            padding: 12px 16px 12px 16px;
            color: #8E9192;
            font-size: 16px;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-color: #ffffff;

            &:focus,
            &:active {
                color: var(--bs-body-color);
                background-color: var(--bs-body-bg);
                border-color: #86b7fe;
                outline: 0;
                box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);

            }

            &:disabled {
                background: #EFEBEB;
                border-color: #EFEBEB;

                &:focus,
                &:active {
                    border-color: #EFEBEB;
                }
            }
        }

        .multiselect-field {
            .searchWrapper {
                border: 1px solid #E6E6E6;
                background: #ffffff;
                @include border-radius(6px);
                display: flex;
                align-items: center;
                min-height: 48px;
                padding: 7px 13px 2px 13px;
                flex-wrap: wrap;

                .chip {
                    background: rgba(210, 47, 38, 0.14);
                    color: #2E3132;
                    @include border-radius(5px);
                    height: 31px;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    margin-left: 5px;

                    i {
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }

                input {
                    margin: 0px;
                    padding: 0px;
                    height: 31px;
                    margin-bottom: 5px;
                    border: none;
                    outline: none;
                    font-size: 16px;
                    color: #8E9192;
                }

                .icon_down_dir {
                    right: 15px;

                    &::before {
                        display: none;
                    }
                }
            }

            .optionListContainer {
                li {
                    &.highlightOption {
                        background: var(--primary-color);
                    }

                    &:hover {
                        background: var(--primary-color);
                    }
                }
            }
        }

        .tagInput-field {
            .rti--container {
                padding: 7px 18px;
                border: 1px solid #E6E6E6;
                @include border-radius(6px);
                gap: 10px;
                min-height: 48px;

                &:focus-within {
                    color: var(--bs-body-color);
                    background-color: var(--bs-body-bg);
                    border-color: #86b7fe;
                    outline: 0;
                    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
                }
            }

            .tag-chip {
                background: rgba(210, 47, 38, 0.14);
                color: #2E3132;
                @include border-radius(5px);
                height: 31px;
                display: flex;
                align-items: center;
                font-size: 16px;
                padding: 4px 10px;
                display: flex;
                align-items: center;
                line-height: normal;

                span {
                    line-height: normal;
                }

                button {
                    font-size: 10px;
                    color: #9d9c9c;
                    padding-right: 0px;

                    &:hover {
                        color: #9d9c9c;
                    }
                }
            }

            .tag-input {
                margin: 0px;
                padding: 0px;
                height: 31px;
                border: none;
                outline: none;
                font-size: 16px;
                color: #8E9192;
                width: auto;
            }
        }

        select {
            background-image: url(../images/dropdown-arrow.svg);
            background-repeat: no-repeat;
            background-position: calc(100% - 15px) center;
        }

        .date-field {
            display: flex;
            margin-left: -10px;
            margin-right: -10px;

            &>* {
                flex-grow: 1;
                margin: 0 10px;
            }
        }

        label {
            font-size: 16px;
            font-weight: 700;
            width: 100%;
            padding-bottom: 5px;

            i {
                font-style: normal;
                color: var(--primary-color);
            }
        }

        .field-length {
            text-align: right;
            width: 100%;
            color: #828282;
            font-size: 14px;
            padding-top: 3px;
        }

        .field-info {
            width: 100%;
            color: #828282;
            font-size: 14px;
            padding-top: 3px;
            padding-left: 16px;
        }

        .field-error {
            width: 100%;
            font-size: 14px;
            color: var(--primary-color);
            padding-top: 3px;
            padding-left: 16px;
            font-weight: 500;
        }

        .field-info-row {
            display: flex;
            width: 100%;

            .field-error {
                flex-grow: 3;
            }
        }


        &.hasError {

            &>input,
            &>select,
            &>textarea,
            .react-datepicker__input-container input,
            .tagInput-field .rti--container,
            .multiselect-field .searchWrapper {
                border-color: var(--primary-color);
            }
        }
    }
}

.select-grid-header {
    background-image: url(../images/dropdown-arrow.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) center;
    border: 1px solid #E6E6E6;
    padding: 4px 6px 4px 6px;
    margin-left: 18px;
    border-radius: 6px;
    min-width: 106px;
    color: #8E9192;
    font-size: 12px;
    text-transform: uppercase;
    outline: none;
    appearance: none;
    background-color: #ffffff;
}

.form-row {
    margin-left: -50px;
    margin-right: -50px;
    display: flex;
    flex-wrap: wrap;

    .form-col {
        width: 50%;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 20px;

        &.full-form-col {
            width: 100%;
        }
    }
}


.button-row {
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
    flex-wrap: wrap;

    &>* {
        margin: 0 10px;
    }
}

/*=================== Form Container End Here =================*/
.multiDropCell,
.rdg-cell {
    overflow: visible;
}

.small-grey-text {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    color: gray;

}

.part-height {
    height: 540px;
    overflow-y: auto;
    /* Change the color of the track (the area behind the thumb) */

}

.part-height::-webkit-scrollbar {
    width: 3px;
    /* Set the width of the scrollbar */
}

.part-height::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Set the background color of the track */
}

/* Change the appearance of the scrollbar thumb (the draggable part) */
.part-height::-webkit-scrollbar-thumb {
    background: #000000;
    /* Set the background color of the thumb */
    border-radius: 6px;
    /* Add rounded corners to the thumb */
}

/* Change the appearance when the scrollbar is hovered over */
.part-height::-webkit-scrollbar-thumb:hover {
    background: #E0E0E0;
}

.part-heights {
    min-height: 25vh;
    max-height: 640px;
    overflow-y: auto;
    /* Change the color of the track (the area behind the thumb) */

}

.part-heights::-webkit-scrollbar {
    width: 3px;
    /* Set the width of the scrollbar */
}

.part-heights::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Set the background color of the track */
}

/* Change the appearance of the scrollbar thumb (the draggable part) */
.part-heights::-webkit-scrollbar-thumb {
    background: #000000;
    /* Set the background color of the thumb */
    border-radius: 6px;
    /* Add rounded corners to the thumb */
}

/* Change the appearance when the scrollbar is hovered over */
.part-heights::-webkit-scrollbar-thumb:hover {
    background: #E0E0E0;
  }
.grey-text{
    color: #868688;
}

.top-section {
    margin: 50px 0px;
}
a{
    cursor: pointer;
}

.m_8fb7ebe7:disabled, .m_8fb7ebe7[data-disabled] {
  
    opacity: 1 !important;
  
}